export const MISSING_DOCS_ALERT_INFO_MESSAGES = {
  MULTIPLE_DOCS_MISSING_CURRENTLY_MESSAGE: "To book this shift, please complete these documents:",
  SINGLE_DOC_MISSING_CURRENTLY_MESSAGE: "To book this shift, please complete this document:",
  // visible missing docs message when we have only visible docs missing or both visible & invisible docs missing
  MULTIPLE_VISIBLE_DOCS_MISSING_IN_FUTURE_MESSAGE:
    "You have documents that will expire before this shift. These must be uploaded and approved before you can book:",
  SINGLE_VISIBLE_DOC_MISSING_IN_FUTURE_MESSAGE:
    "You have a document that will expire before this shift. This must be uploaded and approved before you can book:",
  // invisible missing docs message when we have only invisible docs missing
  ONLY_MULTIPLE_INVISIBLE_DOCS_MISSING_IN_FUTURE_MESSAGE:
    "No action needed. These will be processed by Clipboard shortly.",
  ONLY_SINGLE_INVISIBLE_DOC_MISSING_IN_FUTURE_MESSAGE:
    "No action needed. This will be processed by Clipboard shortly.",
  // invisible missing docs message when we have both visible & invisible docs missing
  MULTIPLE_INVISIBLE_DOCS_MISSING_IN_FUTURE_MESSAGE:
    "And these documents will be renewed by Clipboard more than 14 days before they expire:",
  SINGLE_INVISIBLE_DOC_MISSING_IN_FUTURE_MESSAGE:
    "And this document will be renewed by Clipboard more than 14 days before they expire:",
  MESSAGE_FOR_INVISIBLE_DOCS_MISSING_CURRENTLY:
    "These documents are being processed by our team and do not require any upload action from you:",
  MULTIPLE_PENDING_DOCS_MESSAGE:
    "These documents have been successfully submitted and will be reviewed by our team:",
  SINGLE_PENDING_DOC_MESSAGE:
    "This document has been successfully submitted and will be reviewed by our team:",
};
